import { FC } from 'react';
import { useFetchContext } from 'app/providers/FetchProvider';

import {
  AppContainer,
  AppHeader,
  MenuAncorContainer,
  MenuTypeContainer,
  ProductsGroup,
} from 'components';

import { ProductCardDish } from 'components/cards/ProductCard';
import { BtnToTop } from 'components/common/BtnToTop';
import { NavigationBar } from 'components/app/NavigationBar';

export const DishesPage: FC = () => {
  const data = useFetchContext();

  const categories = data?.dish?.data ?? [];

  const sortedCategories = categories.sort((a: any, b: any) => {
    if (a.attributes.category_order > b.attributes.category_order) {
      return 1;
    }

    if (a.attributes.category_order < b.attributes.category_order) {
      return -1;
    }
    return 0;
  });

  return (
    <AppContainer>
      <AppHeader />
      <NavigationBar>
        <MenuTypeContainer />
        <MenuAncorContainer categories={sortedCategories} />
      </NavigationBar>
      {sortedCategories.map((category: any, i: number) => {
        const categoryTitle = category.attributes.title ?? '';
        const dishes = category?.attributes?.dishes?.data ?? [];
        const key = categoryTitle || i;
        const bgImage =
          category?.attributes?.img?.data?.attributes?.formats?.thumbnail
            ?.url ?? '';

        return (
          <ProductsGroup
            title={categoryTitle}
            key={key}
            bgImage={bgImage || ''}
          >
            {
              dishes
                .filter((x: any) => !x.attributes.is_discount)
                .map((dishObj: any, i: number) => {
                  const dish = dishObj.attributes ?? {};

                  return (
                    <ProductCardDish
                      key={dish.title}
                      title={dish.title}
                      content={dish.content}
                      weight={dish.weight}
                      price={dish.price}
                      is_discount={dish.is_discount}
                    />
                  );
              })
            }
          </ProductsGroup>
        );
      })}
      <BtnToTop />
    </AppContainer>
  );
};
