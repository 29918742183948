import { FC } from 'react';

import { Container, Title, TitleCompact } from './styled';

interface Props {
  title: string;
  children?: React.ReactNode;
  isCompact?: boolean;
}

export const DrinkLevel3: FC<Props> = ({ title, children, isCompact }) => {
  return (
    <Container>
      {isCompact ? (
        <TitleCompact>{title}</TitleCompact>
      ) : (
        <Title>{title}</Title>
      )}
      {children}
    </Container>
  );
};
