import { DiscountIcon } from 'components/icons/Discount'
import { MenuTypeButton } from '../buttons'
import { GlassIcon, KnifeForkIcon } from '../icons'
import { styled } from 'styled-components'
import { useCallback } from 'react';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.menu.spacing(4)};
  margin-bottom: ${({ theme }) => theme.menu.spacing(2)};
`;

export const MenuTypeContainer = () => {
  const onClickItem = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <MenuTypeButton icon={<GlassIcon />} to="/bar" onClick={onClickItem}>
        Бар
      </MenuTypeButton>
      <MenuTypeButton icon={<KnifeForkIcon />} to="/kitchen" onClick={onClickItem}>
        Кухня
      </MenuTypeButton>
      <MenuTypeButton icon={<DiscountIcon />} to="/discount" onClick={onClickItem}>
        Акции
      </MenuTypeButton>
    </Container>
  )
}
