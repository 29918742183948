import { useFetchContext } from 'app/providers/FetchProvider';
import { ProductCard, ProductCardProps } from '.';
import { DrinkVolumesPrice } from 'shared/types/Strapi';

export const ProductCardDrink = (props: ProductCardProps & { drinkId: number | string }) => {
  const data = useFetchContext();
  const drinkVolumesPrices: DrinkVolumesPrice[] = data?.drinkVolumesPrices?.data;

  let drinkVolumePrice: { volume: string; price: number; }[] = [];

  if (drinkVolumesPrices) {
    const drinkVolPrices = drinkVolumesPrices.filter(x => x.attributes.drink.data.id === props.drinkId);

    if (drinkVolPrices.length > 0) {
      drinkVolumePrice = drinkVolPrices.map(x => ({ volume: x.attributes.volume as string, price: x.attributes.Price }));
    }
  }

  return <ProductCard {...props} pricesByVolume={drinkVolumePrice} />;
};
