import { styled } from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.menu.spacing(5)};

  & > div {
    z-index: 2;
    position: relative;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.menu.palette.grey70};
  margin: 0 0 ${({ theme }) => theme.menu.spacing(2)} 0;
  font-size: 18px;
`;

export const TitleCompact = styled.div`
  color: ${({ theme }) => theme.menu.palette.white};
  margin: 0 0 ${({ theme }) => theme.menu.spacing(2)} 0;
  text-shadow: 0.5px 0.5px 2px #000000;
`;

export const BgWrapper = styled.span`
  display: block;
  position: absolute;
  width: 90px;
  top: -20px;
  right: -45px;
  z-index: 1;
`;

export const BgImage = styled.img`
  width: 90px;
  height: 180px;
  object-fit: contain;
`;
