import {
  ProductCardDescription,
  ProductCardPrice,
  ProductCardContainer,
  ProductCardTitle,
  SlashBetweenPrices,
  ProductCardPriceVolume
} from './styled';

import { StyledRub } from '../shared/styled';
import { DiscountIcon } from 'components/icons/Discount';
export interface ProductCardProps {
  title: string;
  price: number;
  content?: string;
  weight?: string;
  percent_alc?: string;
  volume?: string;
  pricesByVolume?: { volume: string; price: number; }[];
  is_discount: boolean | null;
}

export const ProductCard = ({
  title,
  content,
  price,
  weight,
  percent_alc,
  volume,
  pricesByVolume = [],
  is_discount = false,
}: ProductCardProps) => {
  const existOtherPrices = pricesByVolume.length > 0;

  console.log('price: ', price);

  return (
    <ProductCardContainer>
      <div style={{ marginRight: 'auto' }}>
        <ProductCardTitle>
          {title}&nbsp;
          {is_discount && <DiscountIcon fill={'#C5312D'}/>}
        </ProductCardTitle>
        <ProductCardDescription>{content}</ProductCardDescription>
        <ProductCardDescription>{weight}</ProductCardDescription>
        <ProductCardDescription>
          {percent_alc && <>алк. {percent_alc}%</>}
          {percent_alc && volume && <>, </>}
          {volume && !existOtherPrices && <>объем {volume}</>}
        </ProductCardDescription>
      </div>

      {
        Boolean(price) && <ProductCardPrice>
          {price}
          <StyledRub>&nbsp;₽</StyledRub>
          { existOtherPrices && <ProductCardPriceVolume>{volume}</ProductCardPriceVolume> }
        </ProductCardPrice>
      }

      { existOtherPrices && <SlashBetweenPrices>/</SlashBetweenPrices> }

      {
        existOtherPrices && pricesByVolume.map(x => (<ProductCardPrice>
          {x.price}
          <StyledRub>&nbsp;₽</StyledRub>
          { existOtherPrices && <ProductCardPriceVolume>{x.volume}</ProductCardPriceVolume> }
        </ProductCardPrice>))
      }
    </ProductCardContainer>
  );
};
