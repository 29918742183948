import { styled } from 'styled-components';

export const ProductCardContainer = styled.div`
  padding-block: ${({ theme }) => theme.menu.spacing(1)};
  padding-inline: ${({ theme }) => theme.menu.spacing(2)};
  background-color: ${({ theme }) => theme.menu.palette.cardbg};
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.menu.palette.white};
  margin-bottom: ${({ theme }) => theme.menu.spacing(0.25)};
`;

export const ProductCardTitle = styled.p`
  ${({ theme }) => ({ ...theme.menu.typography.h3 })};
  margin: 0;
  display: flex;
  align-items: center;
`;

export const ProductCardDescription = styled.p`
  ${({ theme }) => ({ ...theme.menu.typography.h4 })};
  margin: 0;
  color: ${({ theme }) => theme.menu.palette.grey70};
`;

export const ProductCardPrice = styled.span`
  ${({ theme }) => ({ ...theme.menu.typography.h1 })};
  margin: 0;
  font-family: ${({ theme }) => theme.menu.fonts.sofiaSans};
  font-style: italic;
`;

export const SlashBetweenPrices = styled.span`
  ${({ theme }) => ({ ...theme.menu.typography.h1 })};
  font-family: ${({ theme }) => theme.menu.fonts.sofiaSans};
  margin-left: ${({ theme }) => theme.menu.spacing(1.5)};
  margin-right: ${({ theme }) => theme.menu.spacing(1.5)};
  font-style: italic;
`;

export const ProductCardPriceVolume = styled.span`
  ${({ theme }) => ({ ...theme.menu.typography.h4 })};
  color: ${({ theme }) => theme.menu.palette.grey70};
  display: block;
  position: relative;
  height: 0;
  top: -5px;
`;