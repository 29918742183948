import { styled } from 'styled-components';

export const MiniCardContainerSt = styled.div`
  padding-block: ${({ theme }) => theme.menu.spacing(1)};
  background-color: ${({ theme }) => theme.menu.palette.cardbg};
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  color: ${({ theme }) => theme.menu.palette.white};
  margin-bottom: 0;

  & > * + * {
    border-left: 1px solid ${({ theme }) => theme.menu.palette.black};
  }
`;

export const MiniCardItem = styled.div`
  text-align: center;
  padding-block: ${({ theme }) => theme.menu.spacing(1)};
  padding-inline: ${({ theme }) => theme.menu.spacing(2)};
  color: ${({ theme }) => theme.menu.palette.white};
  margin-bottom: 0;
  flex-grow: 1;
`;

export const MiniCardVolume = styled.p`
  ${({ theme }) => ({ ...theme.menu.typography.h4 })};
  margin: 0;
  color: ${({ theme }) => theme.menu.palette.grey70};
`;

export const MiniCardPrice = styled.span`
  ${({ theme }) => ({ ...theme.menu.typography.h1 })};
  margin: 0;
  font-family: ${({ theme }) => theme.menu.fonts.sofiaSans};
  font-style: italic;
`;
