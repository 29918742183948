import { FC } from 'react';
import { useFetchContext } from 'app/providers/FetchProvider';
import { Dish, Drink, DrinkCategory, ProductItem } from 'shared/types/Strapi';

import {
  AppContainer,
  AppHeader,
  MenuAncorContainer,
  MenuTypeContainer,
  ProductsGroup,
} from 'components';

import { ProductCardDish, ProductCardDrink } from 'components/cards/ProductCard';
import { DrinkLevel2 } from 'components/containers/DrinkLevel2';
import { DrinkLevel3 } from 'components/containers/DrinkLevel3';
import { MiniCard, MiniCardContainer } from 'components/cards/MiniCard';
import { BtnToTop } from 'components/common/BtnToTop';
import { NavigationBar } from 'components/app/NavigationBar';

function renderDrinkItems(arr: Array<ProductItem>) {
  return (
    <>
      {arr.map((drinkObj: any, i: number) => {
        const drink = drinkObj.attributes ?? {};

        return (
          <ProductCardDrink
            drinkId={drinkObj.id}
            key={drink.title}
            title={drink.title}
            content={drink.content}
            price={drink.price}
            weight={drink.weight}
            percent_alc={drink.percent_alc}
            volume={drink.volume}
            is_discount={drink.is_discount}
          />
        );
      })}
    </>
  );
}

function renderDishItems(arr: Array<ProductItem>) {
  return (
    <>
      {arr.map((dishObj: any, i: number) => {
        const dish = dishObj.attributes ?? {};

        return (
          <ProductCardDish
            key={dish.title}
            title={dish.title}
            content={dish.content}
            weight={dish.weight}
            price={dish.price}
            is_discount={dish.is_discount}
          />
        );
      })}
    </>
  );
}

export const DiscountPage: FC = () => {
  const data = useFetchContext();

  let drinks: Drink[] = [];
  let dishes: Dish[] = [];

  if (data?.drink?.data) {
    drinks = data?.drink?.data?.filter((x: Drink) => x.attributes.is_discount === true);
  }

  if (data?.dishes?.data) {
    dishes = data?.dishes?.data?.filter((x: Dish) => x.attributes.is_discount === true);
  }

  const names = {
    drinks: 'Напитки по акции',
    dishes: 'Блюдо по акции'
  };
  
  return (
    <AppContainer>
      <AppHeader />
      <NavigationBar>
        <MenuTypeContainer />
        <MenuAncorContainer categories={[names.dishes, names.drinks]} titleMapperFn={(item) => item} />
      </NavigationBar>
      <ProductsGroup
        title={names.drinks}
        content=""
      >
        {renderDrinkItems(drinks)}
      </ProductsGroup>
      <ProductsGroup
        title={names.dishes}
        content=""
      >
        {renderDishItems(dishes)}
      </ProductsGroup>
      <BtnToTop />
    </AppContainer>
  );
};
