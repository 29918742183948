import { styled } from 'styled-components';

export const Container = styled.div`
  position: relative;
  max-width: 840px;
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.menu.spacing(5)};

  & > div {
    z-index: 2;
    position: relative;
  }
`;

export const AnchorTarget = styled.div`
  position: relative;
  top: -180px;
`;

export const BgImage = styled.img`
  position: absolute;
  width: 200px;
  height: 200px;
  object-fit: contain;
  top: -5px;
  right: -90px;
  z-index: 1;
`;

export const BgImageDrinklevel1 = styled.img`
  position: absolute;
  width: 90px;
  height: 180px;
  object-fit: contain;
  top: -20px;
  right: -45px;
  z-index: 1;
`;

export const Title = styled.p`
  ${({ theme }) => ({ ...theme.menu.typography.h2 })};
  color: ${({ theme }) => theme.menu.palette.white};
  margin: 0 0 ${({ theme }) => theme.menu.spacing(2)} 0;
`;

export const SubTitle = styled.div`
  color: ${({ theme }) => theme.menu.palette.white};
  margin: 0 0 ${({ theme }) => theme.menu.spacing(2)} 0;
`;
