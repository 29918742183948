import { FC } from 'react';
import { useFetchContext } from 'app/providers/FetchProvider';
import { DrinkCategory, ProductItem } from 'shared/types/Strapi';

import {
  AppContainer,
  AppHeader,
  MenuAncorContainer,
  MenuTypeContainer,
  ProductsGroup,
} from 'components';

import { ProductCardDrink } from 'components/cards/ProductCard';
import { DrinkLevel2 } from 'components/containers/DrinkLevel2';
import { DrinkLevel3 } from 'components/containers/DrinkLevel3';
import { MiniCard, MiniCardContainer } from 'components/cards/MiniCard';
import { BtnToTop } from 'components/common/BtnToTop';
import { NavigationBar } from 'components/app/NavigationBar';

function renderItems(arr: Array<ProductItem>) {
  return (
    <>
      {
        arr
          .filter((x: ProductItem) => !x.attributes.is_discount)
          .map((drinkObj: any, i: number) => {
            const drink = drinkObj.attributes ?? {};

            return (
              <ProductCardDrink
                drinkId={drinkObj.id}
                key={drink.title}
                title={drink.title}
                content={drink.content}
                price={drink.price}
                weight={drink.weight}
                percent_alc={drink.percent_alc}
                volume={drink.volume}
                is_discount={drink.is_discount}
              />
            );
        })
      }
    </>
  );
}

function renderSubcategoryMiniItems(arr: Array<ProductItem>) {
  return (
    <MiniCardContainer>
      {arr.map((drinkObj: any, i: number) => {
        const drink = drinkObj.attributes ?? {};

        return (
          <MiniCard
            key={drink.title}
            price={drink.price}
            volume={drink.volume}
          />
        );
      })}
    </MiniCardContainer>
  );
}

const renderLevel3 = (category: DrinkCategory) => {
  // level3

  if (category.attributes.sub_categories.data.length) {
    return category.attributes.sub_categories.data.map((categoryLevel3) => {
      const title = categoryLevel3.attributes.title ?? '';
      const id = categoryLevel3.id;

      return (
        <DrinkLevel3
          title={title}
          key={id}
          isCompact={categoryLevel3.attributes.is_unites_into_group}
        >
          {renderItems(categoryLevel3.attributes.drinks.data)}
        </DrinkLevel3>
      );
    });
  } else {
    if (category.attributes.is_unites_into_group) {
      return renderSubcategoryMiniItems(category.attributes.drinks.data);
    }
    return renderItems(category.attributes.drinks.data);
  }
};

const renderLevel2 = (category: DrinkCategory) => {
  // level2

  if (category.attributes.sub_categories.data.length) {
    return category.attributes.sub_categories.data.map((categoryLevel2) => {
      const title = categoryLevel2.attributes.title ?? '';
      const id = categoryLevel2.id;
      const bgImageUrl =
        categoryLevel2?.attributes?.img?.data?.attributes?.formats?.thumbnail
          ?.url ?? categoryLevel2?.attributes?.img?.data?.attributes?.url;
      const additionalImagesUrls = categoryLevel2?.attributes?.more_images?.data?.map(x => x.attributes?.formats?.thumbnail?.url)
          ?? categoryLevel2?.attributes?.more_images?.data?.map(x => x.attributes?.url);

      let bgImages: string[] = [];

      if (bgImageUrl) {
        bgImages = [bgImageUrl];
      }

      if (additionalImagesUrls && additionalImagesUrls.length > 0) {
        bgImages = [...bgImages, ...additionalImagesUrls];
      }

      return (
        <DrinkLevel2
          title={title}
          key={id}
          bgImages={bgImages}
          isCompact={categoryLevel2.attributes.is_unites_into_group}
        >
          {renderLevel3(categoryLevel2)}
        </DrinkLevel2>
      );
    });
  } else {
    if (category.attributes.is_unites_into_group) {
      return renderSubcategoryMiniItems(category.attributes.drinks.data);
    }
    return renderItems(category.attributes.drinks.data);
  }
};

const getFullSubcategories = (
  categoriesCurrentLevel: DrinkCategory[],
  allCategoryArr: DrinkCategory[],
) => {
  //строим вложенность
  categoriesCurrentLevel.forEach((categoryCurrentLevel: DrinkCategory) => {
    if (categoryCurrentLevel.attributes.sub_categories.data.length) {
      // получаем все id субкатегорий
      const subСategoriesIdArr =
        categoryCurrentLevel.attributes.sub_categories.data.map(
          (subcategory: DrinkCategory) => subcategory.id,
        );

      let fullSubСategories = subСategoriesIdArr.map((id) => {
        // ищем эти подкатегории в общем списке по id
        const findCategory = allCategoryArr.find(
          (category: DrinkCategory) => category.id === id,
        );

        return findCategory as DrinkCategory;
      });

      // рекурсивно заполняем следующие уровни
      fullSubСategories = getFullSubcategories(
        fullSubСategories,
        allCategoryArr,
      );

      // мутируем данные sub_categories,
      // заменяя их полными данными из общего списка категорий
      categoryCurrentLevel.attributes.sub_categories.data = fullSubСategories;
    }
  });

  return categoriesCurrentLevel;
};

// страпи отдает полный список категорий,
// у которых субкатегории только на 1 уровень вложенности и без товаров.
// функция getFullSubcategories создает дерево категорий,
// наполняя уровни субкатегорий категориями из изначального списка
const getFullTree = (allCategoryArr: DrinkCategory[]) => {
  if (allCategoryArr) {
    // получаем категории первого уровня
    const categories1level: DrinkCategory[] =
      allCategoryArr?.filter(
        (category1level: DrinkCategory) =>
          category1level.attributes.parent_category.data === null,
      ) ?? [];

    return getFullSubcategories(categories1level, allCategoryArr);
  }
  return [];
};

export const DrinksPage: FC = () => {
  const data = useFetchContext();

  let categories: DrinkCategory[] = [];

  if (data?.drinkCategories?.data) {
    categories = getFullTree(data?.drinkCategories?.data);
  }

  const sortedCategories = categories.sort((a, b) => {
    if (a.attributes.category_order > b.attributes.category_order) {
      return 1;
    }

    if (a.attributes.category_order < b.attributes.category_order) {
      return -1;
    }
    return 0;
  });

  return (
    <AppContainer>
      <AppHeader />
      <NavigationBar>
        <MenuTypeContainer />
        <MenuAncorContainer categories={sortedCategories} />
      </NavigationBar>
      {sortedCategories.map((category: any, i: number) => {
        const category1Title = category.attributes.title ?? '';
        const category1Content = category.attributes.content ?? '';

        const key = category1Title || i;
        const bgImageUrl =
          category?.attributes?.img?.data?.attributes?.formats?.thumbnail
            ?.url ?? category?.attributes?.img?.data?.attributes?.url;
        const bgImage = bgImageUrl ?? '';

        return (
          <ProductsGroup
            title={category1Title}
            content={category1Content}
            key={key}
            bgImage={bgImage || ''}
          >
            {renderLevel2(category)}
          </ProductsGroup>
        );
      })}
      <BtnToTop />
    </AppContainer>
  );
};
