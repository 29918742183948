import { CSSProperties } from 'react';

const SPACING_COUNT = 8;

export interface ITheme {
  menu: {
    palette: {
      white: string;
      grey70: string;
      grey90: string;
      black: string;
      blue: string;
      cardbg: string;
    };
    fonts: {
      coreSans: string;
      sofiaSans: string;
    };
    typography: {
      h1: CSSProperties;
      h2: CSSProperties;
      h3: CSSProperties;
      h4: CSSProperties;
    };
    spacing: (num: number) => string;
  };
}

export const menuTheme: ITheme = {
  menu: {
    palette: {
      white: '#FFFFFF',
      grey70: '#63676F',
      grey90: '#1D1F21',
      black: '#000000',
      blue: '#2E42F6',
      cardbg: 'rgba(29, 31, 33, 0.85)',
    },
    fonts: {
      coreSans: 'CoreSans',
      sofiaSans: 'Sofia Sans, sans-serif',
    },
    typography: {
      h1: {
        fontSize: '20px',
        lineHeight: '26px',
        fontWeight: 500,
      },
      h2: {
        fontSize: '26px',
        lineHeight: '26px',
        fontWeight: 600,
      },
      h3: {
        fontSize: '16px',
        lineHeight: '22.4px',
        fontWeight: 400,
      },
      h4: {
        fontSize: '12px',
        lineHeight: '15.6px',
        fontWeight: 400,
      },
    },
    spacing: (num: number) => `${num * SPACING_COUNT}px`,
  },
};
