import { FC } from 'react';

import { DishesPage, DrinksPage, DiscountPage } from 'pages';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

const RootRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DishesPage />} />
        <Route path="/kitchen" element={<DishesPage />} />
        <Route path="/bar" element={<DrinksPage />} />
        <Route path="/discount" element={<DiscountPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RootRoutes;
