import { css, styled } from 'styled-components'

export const Container = styled.div`
  display: flex;
  overflow-x: scroll;
  align-items: center;
  column-gap: ${({ theme }) => theme.menu.spacing(1)};
  margin-bottom: ${({ theme }) => theme.menu.spacing(4)};
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Button = styled.button<{ $selected?: boolean }>`
  ${({ theme }) => ({ ...theme.menu.typography.h3 })};
  border: none;
  outline: none;
  flex-basis: fit-content;
  flex-shrink: 0;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.menu.palette.grey90};
  border-radius: 30px;
  background-color: ${({ theme }) => theme.menu.palette.black};
  color: ${({ theme }) => theme.menu.palette.white};
  padding-inline: ${({ theme }) => theme.menu.spacing(1.5)};
  padding-block: ${({ theme }) => theme.menu.spacing(0.5)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $selected }) =>
    $selected &&
    css`
      border-color: transparent;
      background-color: ${({ theme }) => theme.menu.palette.grey90};
    `}
`
