import { FC, useCallback, useState } from 'react';
import { styled } from 'styled-components';

const Wrap = styled.div`
  margin: 0 -32px;
  margin-bottom: ${({ theme }) => theme.menu.spacing(3)};

  height: 31px;
  overflow: hidden;
`;

const Container = styled.div`
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100px;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))
      0 0 no-repeat;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: ${({ theme }) => theme.menu.spacing(1)};
  padding-bottom: 20px;
  max-width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x;
  scroll-padding: 30px;

  &:before {
    content: '';
    display: block;
    width: 22px;
    flex-shrink: 0;
    height: 10px;
  }
  &:after {
    content: '';
    display: block;
    width: 100px;
    flex-shrink: 0;
    height: 10px;
  }
`;

const Btn = styled.a`
  scroll-snap-align: start;

  border: 1px solid ${({ theme }) => theme.menu.palette.grey90};
  border-radius: 30px;
  background-color: ${({ theme }) => theme.menu.palette.black};
  color: ${({ theme }) => theme.menu.palette.white};
  padding: 5px 20px;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:hover {
    background-color: ${({ theme }) => theme.menu.palette.grey90};
  }
`;

type Props = {
  categories: any[];
  titleMapperFn?: (item: any) => string;
};

export const MenuAncorContainer: FC<Props> = ({
  categories,
  titleMapperFn = (item: any) => item.attributes.title,
}) => {
  const [freeToClickInitial, setFreeToClickInitial] = useState(true);
  const time = 300;

  const onClickBtn = useCallback((title: string) => {
    const element = document.querySelector(`a[href^="#${title}"]`);

    if (freeToClickInitial) {
      setTimeout(() => {
          setFreeToClickInitial(false);
          (element as any).click();
          console.log('clicked');
          setTimeout(() => {
            setFreeToClickInitial(true);
          }, time);
      }, time);
    }
  }, [freeToClickInitial, setFreeToClickInitial]);

  return (
    <Wrap>
      <Container>
        <Row>
          {categories.map((item: any) => (
            <Btn key={titleMapperFn(item)} href={`#${titleMapperFn(item)}`} onClick={() => onClickBtn(titleMapperFn(item))}>
              {titleMapperFn(item)}
            </Btn>
          ))}
        </Row>
      </Container>
    </Wrap>
  );
};
