import { FC } from 'react';

import { BgImage, Container, AnchorTarget, Title, SubTitle } from './styled';

interface Props {
  title: string;
  content?: string;
  bgImage?: string;
  children?: React.ReactNode;
}

export const ProductsGroup: FC<Props> = ({
  title,
  content,
  bgImage,
  children,
}) => {
  return (
    <Container>
      <AnchorTarget id={title}/>
      <Title>{title}</Title>
      {<SubTitle>{content}</SubTitle>}

      {children}

      {bgImage ? <BgImage src={bgImage} /> : null}
    </Container>
  );
};
