import { FC } from 'react';
import { styled } from 'styled-components';

import { IMAGES } from 'shared/assets';
import { BurgerButton } from '../buttons';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AppHeader: FC = () => {
  return (
    <Header id="header">
      <img src={IMAGES.logo} alt="Logo" />
    </Header>
  );
};
