import { FC, SVGProps } from 'react'

export const GlassIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#fff"
      d="m15.586 3.879-.017-.261-.112-1.821A1.25 1.25 0 0 0 14.21.625H5.79a1.25 1.25 0 0 0-1.247 1.172l-.114 1.821-.016.259c-.05.752-.088 1.294.145 2.565.065.356.204.885.38 1.555.493 1.875 1.317 5.014 1.317 7.642v2.799a.938.938 0 0 0 .938.937h5.614a.938.938 0 0 0 .938-.938V15.64c0-3.011.832-5.988 1.328-7.766.171-.61.306-1.094.368-1.43.234-1.27.197-1.814.145-2.565ZM14.219 2.02l.058.938a.156.156 0 0 1-.156.166H5.88a.156.156 0 0 1-.156-.166l.058-.938a.156.156 0 0 1 .157-.146h8.125a.156.156 0 0 1 .156.146Z"
    />
  </svg>
)
