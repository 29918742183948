import { FC } from 'react';
import { menuTheme } from 'shared/theme';
import { RootRoutes } from 'routes';
import { ThemeProvider } from 'styled-components';
import { FetchProvider } from 'app/providers/FetchProvider';

const App: FC = () => {
    return (
        <FetchProvider>
            <ThemeProvider theme={menuTheme}>
                <RootRoutes />
            </ThemeProvider>
        </FetchProvider>
    );
};

export default App;
