import { styled } from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.menu.spacing(2)};
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.menu.palette.white};
  margin: 0 0 ${({ theme }) => theme.menu.spacing(2)} 0;
`;
