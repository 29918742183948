import { FC, ReactNode } from 'react'
import { Link, LinkProps, To, useMatch } from 'react-router-dom'
import { styled } from 'styled-components'

const StyledLink = styled(Link)<{ $active: boolean }>`
  ${({ theme }) => ({ ...theme.menu.typography.h1 })};
  color: ${({ $active, theme }) => ($active ? theme.menu.palette.white : theme.menu.palette.grey70)};
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.menu.spacing(1)};
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  svg path {
    fill: ${({ $active, theme }) => ($active ? theme.menu.palette.white : theme.menu.palette.grey70)};
  }
`

interface Props {
  icon?: ReactNode
  children: ReactNode
  active?: boolean
  to: To
}

export const MenuTypeButton: FC<Props & LinkProps> = ({ icon, children, to, ...props }) => {
  const match = useMatch(to.toString())

  return (
    <StyledLink to={to} $active={!!match} {...props}>
      {icon}
      {children}
    </StyledLink>
  )
}
