import { FC } from 'react';

import { Container, Title, BgWrapper, BgImage, TitleCompact } from './styled';

interface Props {
  title: string;
  children?: React.ReactNode;
  bgImages?: string[];
  isCompact?: boolean;
}

export const DrinkLevel2: FC<Props> = ({
  title,
  children,
  bgImages = [],
  isCompact,
}) => {
  const existBgImages = bgImages.length > 0;
  const minHeight = existBgImages ? '160px' : '0';

  return (
    <Container style={{ minHeight }}>
      {isCompact ? (
        <TitleCompact>{title}</TitleCompact>
      ) : (
        <Title>{title}</Title>
      )}
      {children}

      {existBgImages && <BgWrapper>{bgImages.map(bg => <BgImage src={bg} />)}</BgWrapper>}
    </Container>
  );
};
