import { Dish, DishCategory, Drink, DrinkCategory, DrinkVolumesPrice } from 'shared/types/Strapi';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface DishCategoriesPopulate {
  dish: {
    data: DishCategory[];
  };
}

interface DrinkCategoriesPopulate {
  drink: {
    data: DrinkCategory[];
  };
}

interface DrinkVolumesPricesPopulate {
  drink: {
    data: DrinkVolumesPrice[];
  };
}

interface DrinksPopulate {
  data: Drink[];
}

interface DishesPopulate {
  data: Dish[];
}

interface FetchContextPayload {
  dish?: any;
  drinkCategories?: any;
  drinkVolumesPrices?: any;
  drink?: any;
  dishes?: any;
  isLoaded?: boolean;
}
type QueryUrl = FetchContextPayload['dish'] | FetchContextPayload['drink'];

const FetchContext = createContext<FetchContextPayload>({});

const baseUrl = process.env.REACT_APP_BACKEND_URL + '/api';
const dishCategoryPopulateUrl = 'dish-categories?populate=*';
const drinkCategoryPopulateUrl = 'drink-categories?populate=*';
const drinkVolumePricesPopulateUrl = 'drink-volume-prices?populate=*';
const drinkPopulateUrl = 'drinks?populate=*';
const dishesPopulateUrl = 'dishes?populate=*';

const fetchApi = async (queryUrl: QueryUrl) => {
  try {
    const response = await fetch(`${baseUrl}/${queryUrl}`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

const getAsyncFetch = async () =>
  Promise.all([
    fetchApi(dishCategoryPopulateUrl),
    fetchApi(drinkCategoryPopulateUrl),
    fetchApi(drinkVolumePricesPopulateUrl),
    fetchApi(drinkPopulateUrl),
    fetchApi(dishesPopulateUrl),
  ]);

export const FetchProvider = ({ children }: { children: ReactNode }) => {
  const DishRef = useRef<DishCategoriesPopulate>();
  const DrinkCategoriesRef = useRef<DrinkCategoriesPopulate>();
  const DrinkVolumesPricesRef = useRef<DrinkVolumesPricesPopulate>();
  const DrinkRef = useRef<DrinksPopulate>();
  const DishesRef = useRef<DishesPopulate>();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getAsyncFetch().then(([Dish, DrinkCategories, DrinkVolumesPrices, Drinks, Dishes]) => {
      DishRef.current = Dish;
      DrinkCategoriesRef.current = DrinkCategories;
      DrinkVolumesPricesRef.current = DrinkVolumesPrices;
      DrinkRef.current = Drinks;
      DishesRef.current = Dishes;
      setIsLoaded(true);
    });
  }, []);

  const value = useMemo(
    () => ({
      drinkCategories: DrinkCategoriesRef.current,
      dish: DishRef.current,
      drinkVolumesPrices: DrinkVolumesPricesRef.current,
      drink: DrinkRef.current,
      dishes: DishesRef.current,
      isLoaded,
    }),
    [isLoaded],
  );

  return (
    <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
  );
};

export const useFetchContext = () =>
  useContext(FetchContext) as Required<FetchContextPayload>;
