import { ToTopIcon } from 'components/icons';
import { FC, useCallback } from 'react';
import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.menu.spacing(5)};
`;

export const Button = styled.a`
  ${({ theme }) => ({ ...theme.menu.typography.h3 })};
  border: none;
  outline: none;
  flex-basis: fit-content;
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.menu.palette.grey90};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.menu.palette.black};
  color: ${({ theme }) => theme.menu.palette.white};
  padding: ${({ theme }) => theme.menu.spacing(1.5)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.menu.spacing(1)};

  text-decoration: none;
  flex-grow: 1;
  max-width: 400px;
`;

export const BtnToTop: FC = () => {
  const onClick = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Button onClick={onClick}>
        <ToTopIcon />
        <span>Вернуться наверх</span>
      </Button>
    </Container>
  );
};
