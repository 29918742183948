import { FC, ReactNode, useEffect, useState } from "react"
import styled from "styled-components";

interface Props {
    children: ReactNode
}

export const NavigationBar: FC<Props> = ({ children }) => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);

        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            windowHeight > 100 ? setIsSticky(true) : setIsSticky(false);
        }
    };

    return <Wrapper isSticky={isSticky}>{children}</Wrapper>
}

const Wrapper = styled.div<{ isSticky: boolean }>`
    position: relative;

    ${({ isSticky, theme }) => isSticky && `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 ${theme.menu.spacing(4)};
        padding-top: 10px;
        max-width: 1000px;
        margin: 0 auto;
        z-index: 999;
        background: 0 0 url('/img/header-bg.jpg') no-repeat;
    `}
`;