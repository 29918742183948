import {
  MiniCardItem,
  MiniCardPrice,
  MiniCardVolume,
  MiniCardContainerSt,
} from './styled';

import { StyledRub } from '../shared/styled';

export interface MiniCardProps {
  price: number;
  volume: string;
}

export interface MiniCardContainerProps {
  children?: React.ReactNode;
}

export const MiniCard = ({ price, volume }: MiniCardProps) => {
  return (
    <MiniCardItem>
      <MiniCardPrice>
        {price}
        <StyledRub>&nbsp;₽</StyledRub>
      </MiniCardPrice>
      <MiniCardVolume>{volume}</MiniCardVolume>
    </MiniCardItem>
  );
};

export const MiniCardContainer = ({ children }: MiniCardContainerProps) => {
  return <MiniCardContainerSt>{children}</MiniCardContainerSt>;
};
